<template>
    <div class="card card-f-h flex flex-col">
        <ConfirmationModal codeConfirmation @success="getAll" />

        <ModalAddService
            :visible="modalAddServiceVisible"
            @refresh="getAll"
            @close="modalAddServiceVisible = false"
        />

        <ModalEditService
            :visible="modalEditServiceVisible"
            :serviceId="selectedServiceId"
            @refresh="getAll"
            @close="modalEditServiceVisible = false"
        />

        <div class="flex items-end justify-between border-b border-gray-300 mb-3 pb-3">
            <el-button type="primary" :disabled="!userCan('create services')" @click="addService">
                {{ $t('services.add') }}
            </el-button>
            <el-input v-model="quickSearch" suffix-icon="el-icon-search" class="w-72" :placeholder="$t('services.search')" clearable />
        </div>

        <ServicesTable
            :tableData="servicesData"
            @handleEdit="handleEdit"
            @handleDelete="handleDelete"
        />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="loading.initial_services" />
    </div>
</template>
<script>
import Api from './services.api';

export default {
    components: {
        ServicesTable:    () => import(/* webpackChunkName: "Services/ServicesTable" */ './components/ServicesTable'),
        ModalAddService:  () => import(/* webpackChunkName: "Services/ModalAddService" */ './modals/ModalAddService'),
        ModalEditService: () => import(/* webpackChunkName: "Services/ModalEditService" */ './modals/ModalEditService'),
    },

    data() {
        return {
            noDataInformation:       false,
            quickSearch:             '',
            servicesData:            [],
            servicesDataOrigin:      [],
            selectedServiceId:       null,
            modalAddServiceVisible:  false,
            modalEditServiceVisible: false,
        };
    },

    watch: {
        quickSearch() {
            this.filter();
        },
    },

    beforeCreate() {
        this.$wait.start('loading.initial_services');
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('services.services'));
        this.$store.commit('setActiveMenuItem', '5-1');
        this.getAll();
    },

    methods: {
        async getAll() {
            try {
                this.$wait.start('loading.services');
                const data = await Api.getAll();
                this.servicesData = Object.freeze(data);
                this.servicesDataOrigin = Object.freeze(data);
                this.noDataInformation = data.length;
            } finally {
                this.$wait.end('loading.services');
                this.$wait.end('loading.initial_services');
            }
        },

        addService() {
            this.modalAddServiceVisible = true;
        },

        handleEdit(id) {
            this.selectedServiceId = id;
            this.modalEditServiceVisible = true;
        },

        handleDelete(id) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  Api.destroyURL(id),
                actionType: 'destroy',
            });
        },

        filter() {
            const query = this.quickSearch.toLowerCase();
            this.servicesData = this.servicesDataOrigin.filter(service => {
                const servicesName = service.name.toLowerCase();
                const categoriesName = service.category.name?.toLowerCase();
                return servicesName.includes(query) || categoriesName?.includes(query);
            });
        },
    },
};
</script>

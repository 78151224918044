export default {
    async getAll() {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/services`, {
                requestId: 'forAxiosCancel',
            });
            return data;
        } catch {
            return [];
        }
    },

    async getDetails(id) {
        const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/services/${id}`);
        return data;
    },

    async create(data) {
        await axios.post(`${process.env.VUE_APP_CORE_API_URL}/services/services`, {
            name:         data.name,
            invoice_name: data.invoice_name,
            price_type:   data.price_type,
            price:        parseInt(data.price.replace(',', '').replace('.', '')),
            tax:          25,
            rut:          data.rut,
            rot:          data.rot,
            color:        data.color,
            category_id:  data.category_id,
        });
    },

    async update(id, data, timbankHours, timbankMinutes) {
        await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/services/services/${id}`, {
            name:         data.name,
            invoice_name: data.invoice_name,
            price_type:   data.price_type,
            price:        parseInt(data.price.replace(',', '').replace('.', '')),
            tax:          25,
            rut:          data.rut,
            rot:          data.rot,
            color:        data.color,
            category_id:  data.category_id,
            fortnox_article_id:  data.fortnox_article_id,
        });
    },

    destroyURL(id) {
        return `${process.env.VUE_APP_CORE_API_URL}/services/services/${id}`;
    },
};
